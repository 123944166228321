.notification__band {
  font-size: 20px;
  padding: 5px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-weight: bold;
  background-color: aqua;
  text-align: center;
}

/* .notification__band > svg {
  margin-right: 5px;
  color: red;
} */
