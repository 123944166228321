.skeleton {
	background: linear-gradient(44deg, #ededed, #485c79, #ededed);
	animation-name: load;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-direction: forwards;
	animation-timing-function: linear;
	background-size: 200% 100%;

	&.block {
		width: 150px;
		height: 5px;

		&.small {
			width: 100px;
		}
	}

	&.circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}
}

.loader {
	display: flex;
	flex-direction: row;
	// background-color: #ffffff;
	padding: 0.5rem;
    margin-left: 5px;
	// box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

	> * + * {
		margin-left: 0.6rem;
	}
}

.skeleton-group {
	display: flex;
	flex-direction: column;
	> * + * {
		margin-top: 7px;
	}
}

@keyframes load {
	from {
		background-position: 100% 0%;
	}
	to {
		background-position: -100% 0%;
	}
}
