.ITJMaintenance__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  z-index: 99999;
  background-color: #ebedef;
}

.ITJMaintenance__Logo {
  width: 20rem;
  margin-bottom: 2rem;
}

.ITJMaintenance__Card {
  margin: 0 10px;
  text-align: center;
}

.ITJMaintenance__Heading {
  font-size: 2rem;
}

.ITJMaintenance__Subheader {
  font-size: 1rem;
}
