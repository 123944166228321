.ITJSplashScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  flex-direction: column;
  background-color: #ebedef;
}

.ITJSplashScreen__Logo {
  width: 20rem;
}

.ITJSplashScreen__Content {
  display: flex;
  align-items: center;
  justify-content: center;
}
