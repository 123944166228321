// Here you can add other styles

// Header Css Section Web Component && Mobile Component Working class

body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
}

.c-body {
  overflow-x: hidden;
}

.c-app {
  background-color: #fff;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.carousel {
  position: initial;
}

//HeaderDropdown Modal Currency Selector CSS Section

.ITJB2__CurrencySelector {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ITJB2__CurrencySelector__Main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 1rem;
}

.ITJB2__CurrencySelector__Main__Item {
  border: 1px solid #3d4b64;
  width: 7rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ITJB2__CurrencySelector__Main__Item > span {
  font-weight: bold;
}

.ITJB2__CurrencySelector__Main__Item__DropDown {
  width: 7rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ITJB2__CurrencySelector__Main__Item__DropDown > select {
  width: inherit;
  height: inherit;
  border-color: #3d4b64;
}

// Search Box component CSS

.cerca__dropdown {
  display: flex;
}

.cerca__autocomplete {
  z-index: 999;
  max-height: 80vh;
  width: 25vw;
  background-color: #fff;
  overflow-y: scroll;
  position: absolute;
  top: 4rem;
  left: 8rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 10px;
  border: 1px solid rgba(247, 240, 240, 0.795);
  box-shadow: 0px 3px 6px #00000029;
}

.cerca__autocomplete__productCount {
  font-weight: bold;
  font-size: 15px;
}

.cerca__autocomplete__dropdown__items__title {
  font-weight: bold;
}

.cerca__autocomplete__dropdown__items__discount {
  font-weight: bold;
}

.cerca__autocomplete__dropdown__items__sellingRate {
  color: #1c7cd6;
  text-decoration: line-through;
  font-weight: 400;
}

.cerca__autocomplete::-webkit-scrollbar {
  display: none;
}

.cerca__autocomplete__dropdown {
  margin: 5px;
  padding: 5px;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cerca__autocomplete__dropdown__image {
  height: 100px;
}

.cerca__autocomplete__dropdown__items {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

// Breadcrumb component CSS

.breadcrumb-item > a {
  color: #3d9be0;
}

.breadcrumb-item.active {
  color: #3d9be0;
}

.DropDown__BredCrum__Icon {
  margin-right: 13px;
}

.DropDown__Break {
  height: 37px;
  background-color: #ebedef;
  color: #8b94a3;
  font-weight: bold;
  justify-content: center;
  cursor: auto;
}

.DropDown__Break__DarkMode {
  height: 37px;
  background-color: #4a4d52;
  color: #8b94a3;
  font-weight: bold;
  justify-content: center;
  cursor: auto;
}

.DropDown__Break > .span {
  font-size: 14px;
  text-align: left;
}

// Header Css Section Web Component Css

.Header__Option {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between !important;
  flex-direction: row;
  max-width: 100%;
}

.Header__Option__first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // flex: 1;
}

.Header__Nav {
  margin-left: 3rem;
}

.Header__middle_item > p {
  font-size: 0.64rem;
  width: 5.8rem;
  margin-right: 1rem !important;
  margin: auto;
  line-height: normal;
}

.Header__middle_item {
  margin-right: 10px;
}

.Header__middle__progress {
  height: 25px;
  width: 10vw;
}

.Header__Cart__key > .progress-bar {
  background-color: #01558c;
}

.Header__Cart__5 > .progress-bar {
  background-color: #04708d;
}

.Header__Cart__10 > .progress-bar {
  background-color: #048b7e;
}

.Header__Cart__null > .progress-bar {
  background-color: #05bf7d;
}

.Header__Option__second {
  display: flex;
}

.Header__Option__second__dropdownSection {
  display: flex;
}

// Header Css Section Web Component Ipad && Ipad pro Css

@media (device-height: 1024px) and (device-width: 768px) {
  .cerca__autocomplete {
    left: 5rem;
    width: 40vw;
  }
}

@media (device-height: 1366px) and (device-width: 1024px) {
  .cerca__autocomplete {
    left: 5rem;
    width: 40vw;
  }
}

// Header Css Section Web Component Custom Cart Component

.Header__middle__nav {
  max-width: 400px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.ITJCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ITJCartButton__Widget {
  background-color: #0064d1;
  height: 35px;
  width: 35px;
  margin-bottom: 10px;
}

.ITJCartButton__Widget > svg {
  color: #fff !important;
  width: 1.2rem;
  margin-left: 8px;
  margin-top: 8px;
}

.ITJCartButton__ProductInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center !important;
  margin-left: 10px;
  margin-bottom: -5px;
}

.ITJCartButton__ProductInfo > h2 {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  font-size: 0.8rem;
  color: #3d9be0;
  margin-bottom: -2px;
}

.ITJCartButton__ProductInfo > p {
  font-size: 0.6rem;
  font-weight: 200;
  color: #86898a;
}

// SideBar Css Section

.ITJSidebar__Header {
  background-color: #485c79;
  margin-bottom: 1rem;
  min-height: 3rem;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
}

// Dark Mode CSS Code

.ITJSidebar__Header__darkMode {
  background-color: #3f465259;
  margin-bottom: 1rem;
  height: 3rem;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
}

.ITJSidebar__Header__Link {
  color: #ced2d8;
}

.ITJSidebar__Header__Link:hover {
  text-decoration: none;
  color: #ced2d8;
}

.ITJSidebar__Header__Link__Body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.ITJSidebar__Header__Name {
  font-weight: bold;
  font-size: 14px;
  margin-left: 13px;
  margin-bottom: 0;
  color: #e3e3e3 !important;
  display: flex;
  align-items: center;
  height: 3rem;
}

.ITJSidebar__Container:last-child {
  margin-bottom: 1rem;
}

.ITJSidebar {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
  padding: 1px 3px;
  cursor: pointer;
}

.ITJSidebar__badge {
  background-color: #4f5d73;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
}

.ITJSidebar__Items {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ITJSidebar > .ITJSidebar__Items > .ITJSidebar__Name {
  font-weight: 500;
  font-size: 1.2rem;
  margin-right: 10px;
  margin-left: 10px;
}

.badge-light {
  color: #fff !important;
  background-color: #485c79 !important;
}

.ITJSidebar > .ITJSidebar__Items > small {
  font-size: 0.8rem;
  font-weight: bold;
  color: #ffddac;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: -1em;
}

.ITJB2SidebarNavIcon_Pdf {
  opacity: 0.5;
  cursor: not-allowed;
}

.ITJSidebarNavIcon {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.ITJSidebarDropDown {
  background-color: #3c4b64;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  cursor: pointer;
}

// Dark Mode CSS Code

.ITJSidebarDropDown__darkMode {
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  cursor: pointer;
}

.ITJSidebarDropDown__darkMode:hover {
  text-decoration: none;
}

.ITJSidebarDropDown:hover {
  text-decoration: none;
  color: #ced2d8;
}

.ITJSidebarDropDown__Item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
}

.ITJSidebarDropDown__Item > h2 {
  margin-top: 5px;
  font-weight: 500;
  font-size: 0.75rem;
  margin-right: 10px;
  color: #fff;
  margin-left: 5px;
}

.ITJSidebarDropDown > .ITJSidebarDropDown__badge__item {
  background-color: #4f5d73;
  font-weight: 300;
  color: #fff;
}

.ITJSidebarDropDown__Item > .ITJSidebarDropDown__icon__item {
  color: #fff;
  margin-bottom: 3px;
}

.ITJSidebarDropDown__Item > .ITJSidebarDropDown__icon__item:hover {
  color: #fff;
}

.ITJSidebar__hotButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 13px;
  cursor: pointer;
}

.ITJSidebar__hotButton__group {
  margin-top: 20px;
}

.ITJSidebar__hotButton > h2 {
  margin-top: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  margin-right: 10px;
  color: #e3e3e3;
}

.ITJSidebar__hotButton__badge {
  width: 2.2rem;
  font-size: 10px;
}
.languageSwitchbar{
  // border:2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  &__block{
    margin: 5px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 45px;
    border-radius: 5px;
    &--selected{
      background-color: rgb(234, 234, 234);
      border: 2px solid #0064d1;
      box-shadow: 0px 2px 4px 1px #6b7ddf;
    }
    &:hover{
      background-color: rgb(234, 234, 234);
      box-shadow: 0px 2px 3px 1px #888888;
    }
  }
}
// Css Section Mobile view Component Css

@media only screen and (max-width: 600px) {
  //Footer height alignment change for mobile view

  .c-footer {
    height: 100px !important;
  }

  // Search Box component CSS

  .cerca__autocomplete {
    top: 8rem;
    left: 20px;
    width: 90%;
  }

  // Header component CSS

  .Header__Nav {
    margin-left: 6px !important;
    max-width: 70%;
    padding-right: 1rem !important;
  }

  .Header__Brand__img {
    width: 100%;
    height: 100%;
    margin-right: 5px;
  }

  .Header__Optional {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: column-reverse;
    max-width: 100%;
    margin-left: inherit;
  }
  .Header__Optional__first {
    display: flex !important;
    justify-content: space-around;
    align-items: center !important;
    width: 100%;
  }
  .Header__Optional__second {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100%;
  }
  .Header__middle_nav_item {
    max-width: 30%;
    margin-right: 6px;
  }
  .form-control {
    width: 50vw !important;
  }
  .ITJCartButton__ProductInfo > h2 {
    width: max-content;
  }
  .ITJCartButton__ProductInfo > p {
    width: max-content;
  }
  .Header__middle_nav_item > p {
    text-align: right;
    margin-bottom: 0.3rem;
  }
  .ITJSidebar__Header {
    margin-top: 1rem;
  }
  .sui-search-box {
    display: flex;
  }
  .sui-search-box__wrapper {
    margin-right: 0.5rem;
  }
  .sui-search-box__submit {
    background-color: #0064d1;
    color: #fff;
    padding: 3px !important;
    margin-right: 5px;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
}

@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .cerca__autocomplete {
    width: 85vw;
  }
}

.avatar-circle {
  border-radius: 50%;
}

.avatar-circle-nav {
  width: 50px;
  height: 50px;
  background: #ebedef;
}

.avatar-circle-profile {
  width: 150px;
  height: 150px;
  background: #ebedef;
}